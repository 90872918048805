const handlePayment = (response, Razorpay) => {
    return new Promise((resolve, reject) => {
        const options = {
            key: "rzp_test_6Jkw7VonDVUl8Z",
            amount: response.amount,
            currency: "INR",
            name: "Dr. Sunil Kale",
            description: "Test Transaction",
            send_sms_hashing: true,
            // image: "https://th.bing.com/th/id/R.50826dca272b5e4dbc4c51cf141e17b5?rik=fM1kCWv5FL1dAg&riu=http%3a%2f%2fclipart-library.com%2fimages_k%2fom-transparent%2fom-transparent-2.png&ehk=r%2bazbFTd93r0zGKMYlLoblnDDGaoxCbo95uz0vWieJ8%3d&risl=&pid=ImgRaw&r=0",
            order_id: response.id,
            handler: function (response) {
                if (response.razorpay_payment_id) {
                    console.log("Payment success:", response);
                    resolve(response);
                } else{
                    console.log("Payment window closed by user");
                    reject('user_closed');
                }
            },
            prefill: {
                name: "",
                email: "",
                contact: "",
            },
            notes: {
                address: "OTT Platform",
            },
            "theme": {
                "color": "#3399cc",
            },
            // "retry": {
            //     "enabled":false,
            // }
        };
        const rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            console.log(response);
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
            rzp1.close();
            reject(response);
        });
        rzp1.open();
    });
};

export default handlePayment;
