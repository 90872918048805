import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './header.css';
import logo from '../image/new_logo.png';

function Header() {
  const location = useLocation();
  const showNavbar = location.pathname !== '/login' 
  && location.pathname !== '/dashboard' 
  && location.pathname !== '/datainputpage' 
  && location.pathname !== '/LinkedHelperAcc' 
  && location.pathname !== '/Adminpanel' && location.pathname !== '/execmngt'  
  && location.pathname !== '/AccountsLedger' && location.pathname !== '/buyplan' 
  && location.pathname !== '/specifications'&& location.pathname !== '/campaign'
  && location.pathname !== '/createplan'&& location.pathname !== '/createplan';
  const [activeLink, setActiveLink] = useState('');
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);
  return (
    showNavbar && (
      <div className="navbar">
      <div className="header">
        <NavLink to="/" activeClassName="active-link">
         
            <img src={logo} alt="ConnectIn Logo" className="header__logo-st" />
         
        </NavLink>
        <div className="header__navigation">
        {/* <p>
            <NavLink
              to="/"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/')}
              className={activeLink === '/' ? 'active-link' : ''}
            >
            Home
            </NavLink>
          </p> */}
          <p>
            <NavLink
              to="/About"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/About')}
              className={activeLink === '/About' ? 'active-link' : ''}
            >
              About
            </NavLink>
          </p>
          <p>
            <NavLink
              to="/Solutions"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/Solutions')}
              className={activeLink === '/Solutions' ? 'active-link' : ''}
            >
              Solution
            </NavLink>
          </p>
          <p>
            <NavLink
              to="/Pricing"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/Pricing')}
              className={activeLink === '/Pricing' ? 'active-link' : ''}
            >
              Pricing
            </NavLink>
          </p>
         
          <p>
            <NavLink
              to="/contactus"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/contactus')}
              className={activeLink === '/contactus' ? 'active-link' : ''}
            >
              Contact Us
            </NavLink>
          </p>

        </div>
       
        <div className="header__login_signup">
          <p className="header__login"> 
            <NavLink
              to="/login"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/login')}
              className={activeLink === '/login' ? 'active-link' : ''}
            >
              Log in
            </NavLink>
          </p>
          <p className="header__signup"> 
            <NavLink
              to="/login"
              activeClassName="active-link"
              onClick={() => handleNavLinkClick('/signup')}
              className={activeLink === '/signup' ? 'active-link' : ''}
            >
              Sign up
            </NavLink>
          </p>
        </div>
        <div className="contact-no-link"><p>CONNECT : (+91) 82753 87644</p></div>
      </div>
    </div>
    )
  );
}
export default Header;     