import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip
} from '@mui/material';
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import './Datainputpage.css';
import Navbar from '../Sidebar/Navbar';
import AdminSidepanel from '../Sidebar/AdminSidepanel';
import {
  GetGraphData,
  AddGraphData,
  GetAllUsers,
  DeleteGraphData
} from '../services/plan-service';
import { FaSave } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Datainputpage = () => {
  const customColumnNames = [
    'Date',
    'Id',
    'Visit & Extract Profiles',
    'Requested Profiles',
    'Request Accepted',
    'Message Sent',
    'Reply Received',
    'Action'
  ];

  const [allRows, setAllRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedDateRowIndex, setSelectedDateRowIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userOptions, setUserOptions] = useState([]);
  const [isNewRowAdded, setNewRowAdded] = useState(false);
  const [message, setMessage] = useState('');
  const [editingRowIndex, setEditingRowIndex] = useState(null); 

  useEffect(() => {
    fetchData();
    fetchUserOptions();
  }, []);

  useEffect(() => {
    filterRows();
  }, [allRows, selectedUserId]);

  const filterRows = () => {
    let filtered = allRows;
    if (selectedUserId && selectedUserId.value !== 'all') {
      filtered = filtered.filter(row => row.dataValues[1] === selectedUserId.value);
    }

    setFilteredRows(filtered);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };

  const fetchData = async (userId = null) => {
    try {
      const data = await GetGraphData(userId);
      const rows = data.map((item) => ({
        id: item.id,
        dataValues: [
          formatDate(item.date),
          item.userId,
          item.visiteAndExtractProfile,
          item.inviteProfile,
          item.acceptProfile,
          item.message,
          item.reply,
        ],
      }));
      setAllRows(rows);
      setFilteredRows(rows);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleContentEditableChange = (newValue, rowIndex, columnIndex) => {
    if (columnIndex === 0) {
      setSelectedDateRowIndex(rowIndex);
    } else {
      setAllRows((prevRows) =>
        prevRows.map((row, i) => {
          const updatedRow = { ...row };
          if (i === rowIndex) {
            updatedRow.dataValues[columnIndex] = newValue;
          }
          return updatedRow;
        })
      );
    }
  };

  const handleDateChange = (date) => {
    if (selectedDateRowIndex !== null) {
      const correctDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      setAllRows((prevRows) =>
        prevRows.map((row, i) => {
          const updatedRow = { ...row };
          if (i === selectedDateRowIndex) {
            updatedRow.dataValues[0] = correctDate.toISOString().split('T')[0];
          }
          return updatedRow;
        })
      );
      setSelectedDateRowIndex(null);
    }
  };
  

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const fetchUserOptions = async () => {
    try {
      const users = await GetAllUsers();
      const formattedUsers = users.map((user) => ({ value: user.user_id, label: user.user_id, userEmail: user.email }));

      console.log('Fetched user options:', formattedUsers);

      setUserOptions(formattedUsers);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  const handleAddRecord = (userId) => {
    const newRow = {
      id: null,
      dataValues: ['', userId, '', '', '', '', ''],
    };
    setAllRows((prevRows) => [newRow, ...prevRows]);
    setNewRowAdded(true);
    setMessage('');
  };


  const handleSaveRecord = async (rowIndex) => {
    try {
      const newData = allRows[rowIndex].dataValues;
  
      const userOption = userOptions.find(user => user.value === newData[1]);
  
      if (!userOption) {
        console.error('Error saving data: Invalid Customer ID.');
        setMessage('Error saving data: Invalid Customer ID.');
        return;
      }
  
      const userEmail = userOption.userEmail;
  
      if (!userEmail) {
        console.error('Error saving data: while updating data.');
        setMessage('Error saving data: while updating data.');
        return;
      }
  
      const postData = {
        visiteAndExtractProfile: newData[2] || 0,
        inviteProfile: newData[3] || 0,
        acceptProfile: newData[4] || 0,
        message: newData[5] || 0,
        reply: newData[6] || 0,
        userEmail: userEmail,
        date: newData[0] || '',
      };
  
      if (allRows[rowIndex].id) {
        await AddGraphData(postData);
        toast.success('Data updated successfully.');
      } else {
        await AddGraphData(postData);
        toast.success('Data added successfully.');
      }
  
      setNewRowAdded(false);
      setEditingRowIndex(null);
    } catch (error) {
      console.error('Error saving data:', error.message);
      if (error.response) {
        console.error('Server Response Data:', error.response.data);
      }
      setMessage('Error saving data.');
    }
  };
  

  const handleDeleteRecord = async (id) => {
    try {
      await DeleteGraphData(id);
      setAllRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setMessage('Data deleted successfully.');
    } catch (error) {
      console.error('Error deleting data:', error.message);
      setMessage('Error deleting data.');
    }
  };

  const handleCloseNewRow = () => {
    setAllRows((prevRows) => prevRows.slice(1));
    setNewRowAdded(false);
  };

  const handleEditRecord = (rowIndex) => {
    setEditingRowIndex(rowIndex);
  };
  const startIndex = currentPage * rowsPerPage;
  const displayedRows = filteredRows.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div>
      <Navbar />
      <AdminSidepanel />
      <div className="data-content">
        <div className="header-container">
          <b className="camping-title">Campaign List</b>
          {message && <p>{message}</p>}
          <div className="calendar-box">
            <div className="search-box-fltr">
              <div className="search-box-fltr">
                <div className="select-userid-container">
                  <div className="selected-info">To filter data and add a new record</div>
                  <Select
                    components={makeAnimated()}
                    isSearchable
                    placeholder="Select Customer ID"
                    value={selectedUserId}
                    options={[{ value: 'all', label: 'All' }, ...userOptions]}
                    onChange={(selectedOption) => {
                      setSelectedUserId(selectedOption);
                      setNewRowAdded(false);
                    }}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newtable-data">
          {filteredRows.length === 0 && (
            <p>
              No data available for the selected customer.{' '}
              <a href="#" onClick={() => handleAddRecord(selectedUserId.value)}>Click here to insert data</a>
            </p>
          )}
          <TableContainer component={Paper} style={{ maxHeight: 440 }}>

            <Table stickyHeader>
              <TableHead>
                <TableRow>

                  {customColumnNames.map((columnName, index) => (
                    <TableCell key={index}>
                      {index === customColumnNames.length - 1 ? (
                        <Tooltip title="save and delete record" arrow>
                          Action
                        </Tooltip>
                      ) : (
                        `${columnName}`
                      )}

                    </TableCell>

                  ))}
                </TableRow>

              </TableHead>

              <TableBody>
                {displayedRows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.dataValues.map((value, columnIndex) => (
                      <TableCell
                        key={columnIndex}
                        className={
                          (isNewRowAdded && rowIndex === 0 && columnIndex > 1 && columnIndex < 7) ||
                            (editingRowIndex === rowIndex && columnIndex > 1 && columnIndex < 7)
                            ? 'editable-highlight'
                            : ''
                        }
                        contentEditable={columnIndex !== 1}
                        onClick={() =>
                          handleContentEditableChange(value, rowIndex + startIndex, columnIndex)
                        }
                        onBlur={(e) =>
                          handleContentEditableChange(
                            e.target.innerText,
                            rowIndex + startIndex,
                            columnIndex
                          )
                        }
                        suppressContentEditableWarning
                      >
                        {columnIndex === 0 && selectedDateRowIndex === rowIndex + startIndex ? (
                          <DatePicker selected={value ? new Date(value) : null} onChange={handleDateChange} />
                        ) : columnIndex === 0 && row.id === null ? (
                          'Select Date'
                        ) : (
                          value
                        )}
                      </TableCell>
                    ))}
                    <TableCell>

                      {isNewRowAdded && rowIndex === 0 ? (
                        <>
                          <button onClick={() => handleSaveRecord(rowIndex + startIndex)} className="savebtn">
                            <FaSave />
                          </button>
                          <IoMdCloseCircle onClick={handleCloseNewRow} className="closebtn" />
                        </>
                      ) : (
                        <>
                          {editingRowIndex === rowIndex ? (
                            
                            <button onClick={() => handleSaveRecord(rowIndex + startIndex)} className="savebtn">
                              <FaSave />
                            </button>                            
                            
                          ) : (
                            <>
                              <IoMdAdd onClick={() => handleAddRecord(row.dataValues[1])} className="removebtn" />
                              <button onClick={() => handleDeleteRecord(row.id)} className="removebtn">
                                <MdDelete />
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredRows.length}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};
export default Datainputpage;