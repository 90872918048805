  import React, { useState, useEffect } from "react";
  import { useNavigate } from 'react-router-dom';
  import Sidebar from '../Sidebar/Sidebar';
  import Navbar from '../Sidebar/Navbar';
  import './buyplan.css';
  import DoneSharpIcon from '@mui/icons-material/DoneSharp';
  import ClearIcon from '@mui/icons-material/Clear';
  import { toast } from 'react-toastify';
  import FiveZeroZero from '../image/500 Internal Server Error-amico.png';
  import useRazorpay from "react-razorpay";
  import handlePayment from '../utils/payment';
  import { BuyPlan, CheckTransaction, getUserPlans } from "../services/plan-service";

  function Buyplan(props) {
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const [discounts, setDiscounts] = useState({});
    const [allPlans, setAllPlans] = useState([]);
    const [comparePlans, setComparePlans] = useState([]);
    const [customerSubscriptions, setCustomerSubscriptions] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState("1");
    const [monthsAndDiscounts, setMonthsAndDiscounts] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
      setSelectedDuration("1Months");
      console.log("Initial selected duration:", selectedDuration);
      fetchAllPlans("1");
    }, []);

    // useEffect(() => {
    //   console.log("Selected duration:", selectedDuration);
    //   fetchAllPlans(selectedDuration);
    // }, [selectedDuration]);

    useEffect(() => {
      const storedPlanStatus = localStorage.getItem('planStatus');
      if (storedPlanStatus) {
        setCustomerSubscriptions(JSON.parse(storedPlanStatus));
      }
    }, []);

    const fetchAllPlans = async (durationKey) => {
      try {
        const userPlans = await getUserPlans(durationKey);
        setAllPlans(userPlans.plansList);
        setComparePlans(userPlans.comparePlansList);
        setCustomerSubscriptions(userPlans.customerSubscriptions);
        setDiscounts(userPlans.discounts);
        setMonthsAndDiscounts(userPlans.monthsAndDiscounts);

        const hasActivePlan = userPlans.customerSubscriptions.some(subscription => subscription.status === "ACTIVE");

        if (!hasActivePlan && userPlans.customerSubscriptions.length > 0) {
          const firstUpcomingPlan = userPlans.customerSubscriptions.find(subscription => subscription.status === "UPCOMING");
          if (firstUpcomingPlan) {
            setCustomerSubscriptions(prevSubscriptions => [
              ...prevSubscriptions,
              {
                plans: {
                  plan_id: firstUpcomingPlan.plans.plan_id,
                },
                status: "UPCOMING"
              }
            ]);
          }
        }
        localStorage.setItem('planStatus', JSON.stringify(userPlans.customerSubscriptions));
      } catch (error) {
        console.error("Error fetching user plans:", error);
      }
    };

    const handleBuyNow = (planId) => {
      BuyPlan(planId)
        .then((buyPlanResponse) => {
          handlePayment(buyPlanResponse, Razorpay)
            .then((paymentResponse) => {
              CheckTransaction(paymentResponse)
                .then((transactionCheckResponse) => {
                  console.log('Payment success:', paymentResponse);
                  console.log('Transaction check response:', transactionCheckResponse);

                  if (transactionCheckResponse?.status === 202) {
                    toast.success("Payment Successful");

                    setCustomerSubscriptions(prevSubscriptions => {
                      const updatedSubscriptions = prevSubscriptions.map(subscription => {
                        if (subscription.plans.plan_id === planId) {
                          return {
                            plans: {
                              plan_id: planId,
                            },
                            status: transactionCheckResponse?.status === "ACTIVE" ? "ACTIVE" : "UPCOMING"
                          };
                        } else {
                          return subscription;
                        }
                      });

                      return updatedSubscriptions;
                    });

                    setForceUpdate(prevState => !prevState);
                  } else {
                    console.error('Transaction check failed:', transactionCheckResponse);
                    let errorMessage = "Payment failed. Please try again later.";
                    let statusCode = 500;
                    navigate('/ErrorPage', { state: { errorMessage, statusCode, errorImg: FiveZeroZero } });
                  }
                })
                .catch((error) => {
                  console.error('Error during transaction check:', error);
                });
            })
            .catch((error) => {
              console.error('Error during payment:', error);
            });
        })
        .catch((error) => {
          console.error('Error during buy plan:', error);
        });
    };

    const handleDurationChange = (durationKey) => {
      setSelectedDuration(durationKey);
      fetchAllPlans(durationKey);
    };

    return (
      <div>
        <Navbar />
        <Sidebar />
        <div className="pricing-container1">
          <table className="pricing-table1">
            <thead>
              <tr>
                <td colSpan={allPlans.length + 1} style={{ textAlign: 'right', paddingRight: '20px', fontSize: '14.5px', color: '#555' }}>
                  All prices are exclusive of GST*
                </td>
              </tr>

              <tr>
                <th>Duration</th>
                {allPlans.map(plan => (
                  <th>{plan.plan_name}</th>

                ))}
              </tr>

              <tr>
                <td>
                  <>
                    {monthsAndDiscounts.map((monthData) => (
                      <button key={monthData.according_month}
                        className={`duration-button1 ${selectedDuration === monthData.according_month ? "active" : ""}`}
                        onClick={() => handleDurationChange(monthData.according_month)}
                      >
                        {monthData.according_month} Months
                        <span className="discount-label1">{monthData.discount * 1}% off</span>
                      </button>

                    ))}
                  </>
                </td>
                {allPlans.map(plan => (
                  <td key={plan.plan_id}>
                  <div style={{fontSize:'33px'}}>₹ {plan.price.toLocaleString('en-IN')}</div>
                  <div>/ month</div>
                    {customerSubscriptions.find(subscription => subscription.plans.plan_id === plan.plan_id)?.status === "UPCOMING" ? (
                      <p className="upcoming-plan-label1">Upcoming</p>
                    ) : customerSubscriptions.find(subscription => subscription.plans.plan_id === plan.plan_id)?.status === "ACTIVE" ? (
                      <p className="active-plan-label1">Active</p>
                    ) : (
                      <button className="buy-button1" onClick={() => handleBuyNow(plan.plan_id)}>
                        Buy Now
                      </button>
                    )}

                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Includes:</th>
                {comparePlans.map(comparePlan => (
                  <th key={comparePlan.compare_plan_id}></th>
                ))}
              </tr>
              {["lead_generation", "prospect_identify", "invite_connection", "message_followups", "reply_detection", "linkedin_page_follow_request", "cold_mail_campaign"].map((feature, index) => (
                <tr key={index}>
                  <td>{feature.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</td>
                  {comparePlans.map(comparePlan => (
                    <td key={comparePlan.compare_plan_id}>
                      {comparePlan[feature] === true ? <DoneSharpIcon /> : comparePlan[feature] === false ? <ClearIcon /> : comparePlan[feature]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
    );
  }

  export default Buyplan;
