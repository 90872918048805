import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Tooltip,
    MenuItem
} from '@mui/material';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
} from '@mui/material';
import { MdPlaylistAdd } from "react-icons/md";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Sidebar/Navbar';
import './specifications.css';
import { GetProjectSpecification, addProjectSpecification } from '../services/plan-service';
import { IoMdArrowRoundBack } from "react-icons/io";

function Specifications() {
    const [formData, setFormData] = useState({
        job_title: "",
        company_name: "",
        suspect_Name: "",
        department: "",
        seniority_level: "",
        industry: "",
        employee_Size: "",
        revenue: "",
        location: ""
    });

    const [message, setMessage] = useState("");
    const [displayMessage, setDisplayMessage] = useState(false);
    const [errorMessageClass, setErrorMessageClass] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [setActiveButton] = useState('allSpecifications');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isAnyFieldEmpty = Object.values(formData).some(value => value === "");
        if (isAnyFieldEmpty) {
            toast.error('Please fill all information');
        } else {
            setOpenDialog(true);
        }
    };

    const handleCancel = () => {
        setFormData({
            job_title: "",
            company_name: "",
            suspect_Name: "",
            department: "",
            seniority_level: "",
            industry: "",
            employee_Size: "",
            revenue: "",
            location: ""
        });

        setMessage("");
        setDisplayMessage(false);
        setErrorMessageClass("");
        setIsViewMode(false);
    };

    const handleViewData = async () => {
        try {
            const data = await GetProjectSpecification();
            setTableData(data);
            setIsViewMode(true);
        } catch (error) {
            console.error('Error fetching project specification:', error);
        }
    };

    const handleAddNew = () => {
        setFormData({
            job_title: "",
            company_name: "",
            suspect_Name: "",
            department: "",
            seniority_level: "",
            industry: "",
            employee_Size: "",
            revenue: "",
            location: ""
        });

        setMessage("");
        setDisplayMessage(false);
        setErrorMessageClass("");
        setIsViewMode(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogSubmit = async () => {
        try {
            const response = await addProjectSpecification(formData);
            console.log(response);
            setFormData({
                job_title: "",
                company_name: "",
                suspect_Name: "",
                department: "",
                seniority_level: "",
                industry: "",
                employee_Size: "",
                revenue: "",
                location: ""
            });

            setDisplayMessage(true);
            setErrorMessageClass("");
            setIsViewMode(true);
            toast.success('Specifications submitted successfully');
        } catch (error) {
            console.error("Error adding project specification:", error);
            console.error("Error message:", error.message);
            if (error.response && error.response.status === 400) {
                setMessage("Cannot edit specifications created within the last 7 days");
                setDisplayMessage(true);
                setErrorMessageClass("error-message-7-days");
            } else {
                setMessage(error.message);
                setDisplayMessage(true);
                setErrorMessageClass("");
            }
        }
        setOpenDialog(false);
    };

    const handleViewDetails = (rowData) => {
        setSelectedRowData(rowData);
        setOpenDetailsDialog(true);
    };

    const handleDetailsDialogClose = () => {
        setOpenDetailsDialog(false);
    };

    useEffect(() => {
        handleViewData();
    }, []);

    const handleBackButtonClick = () => {
        setIsViewMode(true);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };
    const jobTitles = ['Chief', 'VP', 'General Manager', 'Head', 'Director', 'Manager', 'Senior', 'Staff'];
    const departments = ['IT', 'Marketing', 'Sales', 'HR', 'Finance', 'Operation', 'General manager', 'Engineering', 'Legal', 'Other'];
    const employeeSizes = ['1-10', '10-50', '50 - 200', '200 - 500', '500 - 1K', '1K - 5K', '5K - 10K', '10K+'];
    const industries = [
        'BFSI', 'IT & computer Software', 'Manufacturing', 'Healthcare', 'Pharmaceutical', 'Accounting', 'Animations', 'Education',
        'Automotive', 'Media & Entertainment', 'Chemical', 'Computer & Network Security', 'Computer Game', 'Electronics', 'Food and Beverages',
        'Human resources', 'Import & Export', 'Internet', 'Legal', 'Logistics and supply chain', 'Luxury Goods & Jewelry', 'Travel & Tourism',
        'Machinery', 'Management Consulting', 'Marketing and advertising', 'Real Estate', 'Restaurant', 'Retail', 'Semiconductor', 'Sports',
        'Telecommunications', 'Translation and localization', 'Utilities', 'Venture capital & Private Equity', 'Veterinary', 'Warehousing', 'Wholesales', 'Other'
    ];
    const revenues = ['$1k - $1M', '$1M - $10M', '$10M - $50M', '$50M - $100M', '$100M - $1B', '$1b+'];

    return (
        <div>
            <Navbar />
            <Sidebar />
            <div className="campaigndata">
                {!isViewMode && (
                    <div className="back-btn" onClick={handleBackButtonClick}><IoMdArrowRoundBack /></div>
                )}
                <div>
                    {isViewMode ? (
                        <Typography variant="h5" gutterBottom >
                            <b>Specification List</b>
                        </Typography>
                    ) : (
                        <div>
                            <Typography><b style={{ fontSize: '20px' }}>Specification Form</b></Typography>
                            <Typography variant="h6" gutterBottom className="create-campaign-box">
                                Fill out the form below to add or view specifications.
                            </Typography>
                        </div>

                    )}
                    {!isViewMode && (
                        <Typography variant="h6" color="textSecondary" style={{ fontSize: '13px' }} >
                            Note: After submission, you cannot edit specifications created within the last 7 days.
                        </Typography>
                    )}
                </div>
                {displayMessage && (
                    <div className={`error-message ${errorMessageClass}`}>
                        {message}
                    </div>
                )}
                {isViewMode ? (
                    <div>
                        <div className="add-new-button-container">
                            <Tooltip title="Add New Specification" arrow>
                                <button
                                    variant="contained"

                                    onClick={() => {
                                        handleAddNew();
                                        setActiveButton('addNew');
                                    }}

                                >

                                    <MdPlaylistAdd style={{ fontSize: '1.8rem' }} />

                                </button>
                            </Tooltip>
                        </div>
                        <div className="specification-table">
                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell>Created On</TableCell>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell>Job Title</TableCell>
                                                <TableCell>Suspect Name</TableCell>
                                                <TableCell>Industry</TableCell>
                                                <TableCell>Seniority Level</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Excel Link</TableCell> {/* New column added here */}
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(tableData) && tableData.length > 0 ? (
                                                tableData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((rowData) => (
                                                        <TableRow key={rowData.id}>
                                                            <TableCell>{rowData.id}</TableCell>
                                                            <TableCell>{formatDate(rowData.created_At)}</TableCell>
                                                            <TableCell>{rowData.company_name}</TableCell>
                                                            <TableCell>{rowData.job_title}</TableCell>
                                                            <TableCell>{rowData.suspect_Name}</TableCell>
                                                            <TableCell>{rowData.industry}</TableCell>
                                                            <TableCell>{rowData.seniority_level}</TableCell>
                                                            <TableCell
                                                                className="status-bar"
                                                                style={{ color: rowData.active === "true" ? "black" : rowData.active === "false" ? "black" : "black" }}
                                                            >
                                                                {rowData.active === "true"
                                                                    ? "Accepted"
                                                                    : rowData.active === "false"
                                                                        ? "Rejected"
                                                                        : rowData.active === "requested"
                                                                            ? "Requested"
                                                                            : "Processing"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowData.excelLink ? (
                                                                    <a href={rowData.excelLink} target="_blank" rel="noopener noreferrer">
                                                                        View Excel
                                                                    </a>
                                                                ) : (
                                                                    "No Link"
                                                                )}
                                                            </TableCell> {/* New column data */}
                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{ background: 'white' }}
                                                                    onClick={() => handleViewDetails(rowData)}
                                                                >
                                                                    <RemoveRedEyeIcon style={{ color: 'black' }} />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={5}>No data available</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <TextField
                                label="Job Title"
                                select
                                id="job_title"
                                name="job_title"
                                value={formData.job_title}
                                onChange={handleChange}
                            >
                                {jobTitles.map((title) => (
                                    <MenuItem key={title} value={title}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Company Name"
                                type="text"
                                id="company_name"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleChange}
                            />
                        </div>
                
                        <div className="form-group">
                            <TextField
                                label="Industry"
                                select
                                id="industry"
                                name="industry"
                                value={formData.industry}
                                onChange={handleChange}
                            >
                                {industries.map((industry) => (
                                    <MenuItem key={industry} value={industry}>
                                        {industry}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Department"
                                select
                                id="department"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                            >
                                {departments.map((dept) => (
                                    <MenuItem key={dept} value={dept}>
                                        {dept}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                
                        <div className="form-group">
                            <TextField
                                label="Employee Size"
                                select
                                id="employee_Size"
                                name="employee_Size"
                                value={formData.employee_Size}
                                onChange={handleChange}
                            >
                                {employeeSizes.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        {size}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Revenue"
                                select
                                id="revenue"
                                name="revenue"
                                value={formData.revenue}
                                onChange={handleChange}
                            >
                                {revenues.map((rev) => (
                                    <MenuItem key={rev} value={rev}>
                                        {rev}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                
                        <div className="form-group">
                            <TextField
                                label="Suspect Name"
                                type="text"
                                id="suspect_Name"
                                name="suspect_Name"
                                value={formData.suspect_Name}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Seniority Level"
                                type="text"
                                id="seniority_level"
                                name="seniority_level"
                                value={formData.seniority_level}
                                onChange={handleChange}
                            />
                        </div>
                
                        <div className="form-group">
                            <TextField
                                label="Location"
                                type="text"
                                id="location"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            />
                        </div>
                
                        <div className="button-group">
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                            <Button type="button" onClick={handleCancel} variant="outlined" color="secondary">
                                Clear
                            </Button>
                        </div>
                    </div>
                </form>
                
                )}
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure to submit?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            After submit, you cannot edit specifications created within the last 7 days.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={handleDialogSubmit} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDetailsDialog}
                    onClose={handleDetailsDialogClose}
                    aria-labelledby="details-dialog-title"
                    aria-describedby="details-dialog-description"
                    PaperProps={{
                        style: {
                            maxHeight: '100vh',
                        },
                    }}
                >
                    <DialogTitle className="dialogue-title-spe"><b>Specification Data</b></DialogTitle>
                    <DialogContent>
                        {selectedRowData && (
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Location </TableCell>
                                            <TableCell>{selectedRowData.location}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Created On</TableCell>
                                            <TableCell>{formatDate(selectedRowData.created_At)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Department</TableCell>
                                            <TableCell>{selectedRowData.department}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Suspect Name</TableCell>
                                            <TableCell>{selectedRowData.suspect_Name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Job Title</TableCell>
                                            <TableCell>{selectedRowData.job_title}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Employee Size</TableCell>
                                            <TableCell>{selectedRowData.employee_Size}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Industry</TableCell>
                                            <TableCell>{selectedRowData.industry}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Revenue</TableCell>
                                            <TableCell>{selectedRowData.revenue}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Company Name</TableCell>
                                            <TableCell>{selectedRowData.company_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Seniority Level</TableCell>
                                            <TableCell>{selectedRowData.seniority_level}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDetailsDialogClose} autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    );
};
export default Specifications;